
@import 'helpers/variables';
@import 'helpers/mixins';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: $theme-base-font;
    font-size: 16px;
    line-height: 1.5;
    color: #19191b;
    background: #FFF;
}
.site-wrapper{
    overflow: hidden;
}

.slick-slide{
    &:focus{
        border:none;
        outline:none;
    }
}
h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
    // color: $text-dark;
}


a,
span {
    display: inline-block;
}

a {
    transition: 0.4s;
    color: inherit;
    &:focus {
        outline: none !important;
    }
    &:hover {
        text-decoration: none;
        // color: $primary-color;
    }
    
    &:visited {
        text-decoration: none;
    }
    
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}
ul {
    list-style: none;
    margin: 0;
}

img {
    max-width: 100%;
}

p {
    font-size: 16px;
}
// span{
//     font-size: 13px;
// }

.btn:focus,
.btn:active {
    box-shadow: none;
}

button {
    cursor: pointer;
    background: transparent;
    border: none;
    &:focus {
        outline: none;
    }
}

select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.form-control{
    &:focus{
        box-shadow: none;
    }
}

/* Common Classes */

.section-padding{
    padding-top: 50px;
    padding-bottom: 50px;
    @include tablet {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @include desktops {
        padding-top: 120px;
        padding-bottom: 120px;
    }
}

/* Background Image */
.bg-image{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


/* Flex */

.flex-all-center{
    display:flex;
    justify-content: center;
    align-items: center;
}

.fs-inherit{
    font-size: inherit;
}

/* Animation */

/* Go from zero to full opacity */
@keyframes fadeEffect {
from {opacity: 0;}
to {opacity: 1;}
}

@keyframes fadeInRight {
    from {
      opacity: 0;
    margin-right:-100px; 
    }
  
    to {
      opacity: 1;
      margin-right:0px;
    }
  }
  
  .fadeInRight {
    animation-name: fadeInRight;
  }


.d-xs-block{
    @include mobile{
        display: block!important;
    }
    @include mobile-lg{
        display: block;
    }
}
.d-lg-block{
    @include desktops{
        display: block!important;
    }
}
.d-xl-block{
    @include large-desktops{
        display: block!important;
    }
}
.d-xxl-block{
    @include large-desktops-mid{
        display: block!important;
    }
}
.d-xs-none{
    @include mobile{
        display: none!important;
    }
    @include mobile-lg{
        display: none;
    }
}
.d-lg-none{
    @include desktops{
        display: none!important;
    }
}
.d-xl-none{
    @include large-desktops{
        display: none!important;
    }
}
.d-xxl-none{
    @include large-desktops-mid{
        display: none!important;
    }
}

.flex-lg-right{
    @include desktops{
        display: flex;
        justify-content: flex-end;
    }
}
.flex-md-right{
   @include tablet{
    display: flex;
    justify-content: flex-end;
   }
}
.flex-sm-right{
   @include mobile-lg{
    display: flex;
    justify-content: flex-end;
   }
}




@include large-desktops-mid {
    .col-xxl {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-xxl-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-xxl-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-xxl-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-xxl-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xxl-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-xxl-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-xxl-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xxl-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-xxl-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-xxl-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-xxl-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-xxl-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-xxl-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    
    .offset-xxl-0 {
      margin-left: 0;
    }
    .offset-xxl-1 {
      margin-left: 8.333333%;
    }
    .offset-xxl-2 {
      margin-left: 16.666667%;
    }
    .offset-xxl-3 {
      margin-left: 25%;
    }
    .offset-xxl-4 {
      margin-left: 33.333333%;
    }
    .offset-xxl-5 {
      margin-left: 41.666667%;
    }
    .offset-xxl-6 {
      margin-left: 50%;
    }
    .offset-xxl-7 {
      margin-left: 58.333333%;
    }
    .offset-xxl-8 {
      margin-left: 66.666667%;
    }
    .offset-xxl-9 {
      margin-left: 75%;
    }
    .offset-xxl-10 {
      margin-left: 83.333333%;
    }
    .offset-xxl-11 {
      margin-left: 91.666667%;
    }
  }


#loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #5454D4;
  z-index: 99999;
}
.load-circle {
	position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 50px;
    height: 50px;
	 span {
	  display: inline-block;
	  width: 64px;
	  height: 64px;
	  &:after {
		  content: " ";
		  display: block;
		  width: 46px;
		  height: 46px;
		  margin: 1px;
		  border-radius: 50%;
		  border: 5px solid #ffffff;
		  border-color: #ffffff transparent #ffffff transparent;
		  animation: lds-dual-ring 1.2s linear infinite;	
	  }
	}
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}